import { ThemeOptions } from '@material-ui/core/styles';
import bonavaTheme from './bonava';
import demoTheme from './demo';
import gbTheme from './gb';
import hartlelaTheme from './hartela';
import ionTheme from './ionTheme';
import skanskaTheme from './skanska';
import sonellTheme from './sonell';
import yitTheme from './yit';
import srvTheme from './srv';
import aspenTheme from './aspen';

const themes: { [organisationId: string]: ThemeOptions } = {
  default: demoTheme,
  gbuilder: gbTheme,
  ion: ionTheme,
  sonell: sonellTheme,
  yit: yitTheme,
  bonava: bonavaTheme,
  skanska: skanskaTheme,
  hartela: hartlelaTheme,
  srv: srvTheme,
  aspen: aspenTheme
};

export const createOrganisationTheme = (
  organisation: string,
  themeOverrides: ThemeOptions = {}
) => {
  const theme = themes[organisation];
  return { ...theme, ...themeOverrides };
};

export default themes;
