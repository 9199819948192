import useStyles from './styles';
import defaultHeaderImage from './defaultHeaderImage.png';
import { Typography } from '@material-ui/core';
import { t } from '../../../locales/utils';
import { getOrganisation } from '../../../utils';

const HeaderImage: React.FC = () => {
  const classes = useStyles();
  const organisation = getOrganisation();
  // TODO - create an organisation setting for GB-Portal header image
  const mainImage =
    organisation && organisation === 'aspen'
      ? 'https://aspen101.blob.core.windows.net/aspen/themeCustomizationImages/vjw4slvs3wgczoguv14ayq68-1712649629874.jpeg'
      : defaultHeaderImage;

  return (
    <>
      <div className={classes.imageContainer} data-testid="HeadeImage-imageContainer">
        {mainImage && <img className={classes.image} alt="home" src={mainImage} />}
      </div>
      <div className={classes.layer} data-testid="HeadeImage-layer" />
      <div className={classes.headerContainer} data-testid="HeadeImage-headerContainer">
        <Typography align="left" variant="h3" className={classes.header}>
          {t('header.welcomeTitle')}
        </Typography>
        <Typography align="left" variant="h5" className={classes.subheader}>
          {t('header.welcomeMessage')}
        </Typography>
      </div>
    </>
  );
};

export default HeaderImage;
