import { ThemeOptions } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const primaryMain = '#FFE856';
const secondaryMain = '#222321';
const srvTheme: ThemeOptions = {
  name: 'srv',
  palette: {
    primary: {
      main: primaryMain,
    },
  },
  props: {
    MuiButton: {
      variant: 'outlined'
    }
  },
  typography: {
    fontFamily: ['Helvetica Neue', 'sans-serif'].join(',')
  },
  overrides: {
    MuiButton: {
      outlined: {
        backgroundColor: 'white',
        color: 'black',
        border: `2px solid ${primaryMain}`,
        borderRadius: '0',
        boxShadow: 'none',
        textTransform: 'uppercase',
        '&:hover': {
          color: 'black',
          backgroundColor: primaryMain
        }
      },
      contained: {
        backgroundColor: primaryMain,
        color: 'white',
        borderRadius: '0',
        boxShadow: 'none',
        textTransform: 'uppercase',
        '&:hover': {
          color: 'black',
          backgroundColor: grey[100]
        }
      },
      text: {
        borderRadius: '0',
        backgroundColor: 'transparent',
        color: primaryMain,
        textTransform: 'uppercase',
        '&:hover': {
          color: 'black',
          backgroundColor: 'transparent'
        }
      },
      root: {
        fontFamily: 'Helvetica Neue, sans-serif'
      }
    },
    MuiInputBase: {
      root: {
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'white',
        ['&.MuiAutocomplete-inputRoot']: {
          padding: 0,
          borderRadius: 0
        }
      }
    }
  },
  custom: {
    SectionCard: {
      header: {
        borderBottom: `0.15rem solid ${primaryMain}`,
        paddingBottom: '1rem !important',
        width: 'calc(100% - 8px)', // align border with header content
        margin: '0 auto' // align border with header content
      },
      buttonIcon: {
        display: 'none'
      }
    },
    loginView: {
      loginView: {
        backgroundColor: '#FFFFFF'
      },
      auth0Lock: {
        headerBackGround: {
          background: 'rgba(0,0,0,0) !important'
        },
        loginButtonLabel: {
          color: `${secondaryMain} !important`
        }
      }
    }
  }
};

export default srvTheme;