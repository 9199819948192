import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useGetProjectsQuery from '../../axios/hooks/useGetProjectsQuery';
import LanguageSelect from '../../components/Header/LanguageSelect/LanguageSelect';
import useStartParams from '../../hooks/useStartParams';
import { getOrganisation, isAuthenticated } from '../../utils';
import StartUrlParameters from '../../utils/startParameters';
import SpinnerFullscreen from '../../components/SpinnerFullscreen/SpinnerFullscreen';
import useStyles from './styles';
import useText from '../../hooks/useText';
import LandingPageImage, { ImageProps } from '../../components/LandingPageImage/LandingPageImage';

const Login: React.FC<{ login: null | (() => void) }> = ({ login }) => {
  const classes = useStyles();
  const { organisationId } = useParams();
  const { data, isLoading } = useGetProjectsQuery();
  const startParams = useStartParams();
  const text = useText();
  const fullScreenLogin = data?.organisationID && ['hartela'].includes(data?.organisationID);

  useEffect(() => {
    login?.();
  }, [login]);

  const renderError = () => {
    return (
      <Alert className={classes.error} variant="outlined" severity="error">
        {text(startParams.error, {
          organisation: getOrganisation()
        })}
      </Alert>
    );
  };

  if (isLoading || startParams[StartUrlParameters.connection]) return <SpinnerFullscreen />;

  return (
    <>
      <div className={classes.languageSelector}>
        <LanguageSelect />
      </div>
      {startParams[StartUrlParameters.error] && renderError()}
      {isAuthenticated() && <Navigate replace to={`/${organisationId || getOrganisation()}`} />}
      <Container maxWidth={false} className={classes.loginView} data-testid="Login">
        <div id="login-container" className={classes.loginContainer} />
        {!fullScreenLogin && (
          <LandingPageImage
            {...(data?.organisationSettings.portalLandingPageImage as ImageProps)}
          />
        )}
      </Container>
    </>
  );
};

export default Login;
