import { UserInfoApartment, UserLevel } from '../types/UserInfo';
import { Project } from './../types/Project';
import { getAccessToken, getLang, isAuthenticated } from './index';

export const isCustomerJourneyUser = (level: number) => [2, 3, 4].includes(level);
export const isCoreUser = (level: number) => [5, 6, 7, 8, 9].includes(level);
export const isCoreClientUser = (level: number) => [10, 11, 12].includes(level);

const getUrlParams = (project: Project) =>
  new URLSearchParams({
    projectId: project.id.toString(),
    tenantId: project.settings.tenantId,
    organisationId: project.settings.organisationId,
    lang: getLang() || ''
  });

export const getCoreClientUrl = (project: Project): string => {
  const params = getUrlParams(project);
  params.set('authToken', String(getAccessToken()));

  return `${project.settings.ccUrl}?${params.toString()}`;
};

export const getCoreUrl = (project: Project): string => {
  const url = project.settings.coreUrl;
  const envUrl = url.endsWith('/') ? url.slice(0, -1) : url;

  return `${envUrl}#access_token=${getAccessToken()}`;
};

export const getCJ2Url = (project: Project, apartment: UserInfoApartment) => {
  const params = getUrlParams(project);
  params.set('accessToken', String(getAccessToken()));
  params.set('apartmentId', String(apartment.id));

  return `${project.settings.cj2Url}?${params.toString()}`;
};

export const getCJ3Url = (project: Project): string => {
  const params = getUrlParams(project);
  params.set('accessToken', String(getAccessToken()));

  return `${project.settings.cj3Url}?${params.toString()}`;
};

export const getCustomerJourneyUrl = (project: Project, redirectTo = ''): string => {
  if (project.settings.useCJ3 && project.useCj3) {
    return getCJ3Url(project);
  } else if (
    project.settings.useCJ2 &&
    project.apartments?.length === 1 &&
    (redirectTo === 'cj2' || !redirectTo)
  ) {
    return getCJ2Url(project, project.apartments?.[0] || ({} as UserInfoApartment));
  }

  // CJ1 by default
  return getCoreUrl(project);
};

interface RedirectProps {
  project: Project;
  level: UserLevel;
  redirectTo?: string;
}

export const getRedirectUrl = ({ project, level, redirectTo }: RedirectProps) => {
  if (!isAuthenticated()) return null;

  if (isCoreClientUser(level)) return getCoreClientUrl(project);
  else if (isCoreUser(level)) return getCoreUrl(project);
  else if (isCustomerJourneyUser(level)) return getCustomerJourneyUrl(project, redirectTo);

  return null;
};
