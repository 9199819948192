import { grey } from '@material-ui/core/colors';
import { ThemeOptions } from '@material-ui/core/styles';

const primaryMain = '#8d5a33';
const hartlelaTheme: ThemeOptions = {
  name: 'sonell',
  palette: {
    primary: {
      main: primaryMain
    }
  },
  props: {
    MuiButton: {
      variant: 'outlined'
    }
  },
  typography: {
    fontFamily: ['Helvetica Neue', 'sans-serif'].join(',')
  },
  overrides: {
    MuiButton: {
      outlined: {
        backgroundColor: 'white',
        color: primaryMain,
        border: `2px solid ${primaryMain}`,
        borderRadius: '0',
        boxShadow: 'none',
        textTransform: 'uppercase',
        '&:hover': {
          color: 'white',
          backgroundColor: primaryMain
        }
      },
      contained: {
        backgroundColor: primaryMain,
        color: 'white',
        borderRadius: '0',
        boxShadow: 'none',
        textTransform: 'uppercase',
        '&:hover': {
          color: 'black',
          backgroundColor: grey[100]
        }
      },
      text: {
        borderRadius: '0',
        backgroundColor: 'transparent',
        color: primaryMain,
        textTransform: 'uppercase',
        '&:hover': {
          color: 'white !important',
          backgroundColor: 'transparent'
        }
      },
      root: {
        fontFamily: 'Helvetica Neue, sans-serif'
      }
    },
    MuiInputBase: {
      root: {
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'white',
        ['&.MuiAutocomplete-inputRoot']: {
          padding: 0,
          borderRadius: 0
        }
      }
    }
  },
  custom: {
    SectionCard: {
      header: {
        borderBottom: `0.15rem solid ${primaryMain}`,
        paddingBottom: '1rem !important',
        width: 'calc(100% - 8px)', // align border with header content
        margin: '0 auto' // align border with header content
      },
      buttonIcon: {
        display: 'none'
      }
    },
    loginView: {
      loginView: {
        background:
          //TO BE CHANGED once we have this image in our server
          "url('https://cdn.images.gbuilder.com/thumbor/RYUy5mrqjkyD38CVJVUbMiv_D10=/https://gbgeneric.blob.core.windows.net/gbimages/image_(22).png') center center",
        gridTemplateColumns: 'auto'
      }
    },
    languageSelector: {
      languageSelector: {
        width: '100%'
      }
    }
  }
};

export default hartlelaTheme;
